var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "centerbox" },
      [
        _c("van-nav-bar", {
          attrs: {
            title: "定价",
            "safe-area-inset-top": "",
            fixed: "",
            placeholder: "",
            border: false
          }
        }),
        _c("div", { staticClass: "boxShadow" }, [
          _c("div", { staticClass: "yellowbox" }, [
            _c(
              "div",
              { staticClass: "flex-around tab_div " },
              _vm._l(_vm.productlist, function(items, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    class: _vm.thanIndex == index ? "active" : "",
                    on: {
                      click: function($event) {
                        return _vm.thanTab(index, items.code)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(items.name) + " ")]
                )
              }),
              0
            )
          ]),
          _c("div", { staticClass: "boxgoldprice" }, [
            _vm.tabover
              ? _c("div", { staticClass: "flex-between nowprice" }, [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "pricebox",
                        class: _vm.redup ? "redfont" : "greenfont",
                        staticStyle: { "text-align": "left" }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.pricenowbox.price || "-") + " "
                        ),
                        _vm.redup
                          ? _c("i", { staticClass: "arrowboxImg" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/icon_trend_up@2x.png"),
                                  alt: ""
                                }
                              })
                            ])
                          : _vm._e(),
                        _vm.greendown
                          ? _c("i", { staticClass: "arrowboxImg" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/icon_down.png"),
                                  alt: ""
                                }
                              })
                            ])
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "timebox",
                        staticStyle: { "text-align": "left" }
                      },
                      [_vm._v(_vm._s(_vm.timebox))]
                    )
                  ]),
                  _c("div", { staticClass: "flex flexbox" }, [
                    _c("div", [
                      _c("div", { staticClass: "price_marb price_marl tl" }, [
                        _vm._v(
                          " 今开: " +
                            _vm._s(_vm.pricenowbox.todayPrice || "0") +
                            " "
                        )
                      ]),
                      _c("div", { staticClass: "price_marb price_marl" }, [
                        _vm._v(
                          " 昨开: " +
                            _vm._s(_vm.pricenowbox.yesterdayPrice || "0") +
                            " "
                        )
                      ])
                    ]),
                    _c("div", [
                      _c("div", { staticClass: "price_marb tl" }, [
                        _vm._v(" 最高: "),
                        _c("span", { staticStyle: { color: "#CC0E0F" } }, [
                          _vm._v(" " + _vm._s(_vm.pricenowbox.maxPrice || "-"))
                        ])
                      ]),
                      _c("div", { staticClass: "price_marb" }, [
                        _vm._v("最低: "),
                        _c("span", { staticStyle: { color: "#02790D" } }, [
                          _vm._v(_vm._s(_vm.pricenowbox.minPrice || "-"))
                        ])
                      ])
                    ])
                  ])
                ])
              : _vm._e(),
            !_vm.tabover
              ? _c(
                  "div",
                  { staticClass: "loadigboxdetals" },
                  [
                    _c("van-loading", {
                      attrs: { color: "#FF2D2E", size: "38px" }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "priceboxcenter" }, [
              _vm.tabover
                ? _c("div", [
                    _vm.havedata
                      ? _c(
                          "div",
                          [
                            _c("child", {
                              attrs: {
                                datalist: _vm.huangjin9999,
                                secondprice: _vm.emitdata,
                                data_box: _vm.timelistcenter,
                                ydata: _vm.ydataarr
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                : _vm._e()
            ])
          ])
        ]),
        _c("div", { key: "sell", staticClass: "boxShadow" }, [
          _c(
            "div",
            {
              staticClass: "sell",
              on: {
                click: function($event) {
                  return _vm.buySellLink("sellgold")
                }
              }
            },
            [
              _c("h3", [_vm._v("卖料")]),
              _c("h4", [_vm._v("实时金价 专业回购")])
            ]
          )
        ]),
        _c("div", { key: "buy", staticClass: "boxShadow" }, [
          _c(
            "div",
            {
              staticClass: "buy",
              on: {
                click: function($event) {
                  return _vm.buySellLink("Buygold")
                }
              }
            },
            [
              _c("h3", [_vm._v("买料")]),
              _c("h4", [_vm._v("实时金价 专业销售")])
            ]
          )
        ]),
        _c(
          "van-dialog",
          {
            model: {
              value: _vm.ceringModel,
              callback: function($$v) {
                _vm.ceringModel = $$v
              },
              expression: "ceringModel"
            }
          },
          [
            _c("div", { staticClass: "title" }, [_vm._v("提示")]),
            _c("div", { staticClass: "centerbox_dialog" }, [
              _vm._v(
                " 停盘中，暂时无法进行交易，开盘时间周一至周五早上9:35-02:00 "
              )
            ]),
            _c(
              "div",
              {
                staticClass: "gocer",
                on: {
                  click: function($event) {
                    _vm.ceringModel = false
                  }
                }
              },
              [_vm._v("确定")]
            )
          ]
        ),
        _c(
          "van-dialog",
          {
            model: {
              value: _vm.nocer,
              callback: function($$v) {
                _vm.nocer = $$v
              },
              expression: "nocer"
            }
          },
          [
            _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.tipTitle))]),
            _c("div", { staticClass: "centerbox_dialog" }, [
              _vm._v(_vm._s(_vm.infodetials))
            ]),
            _c("div", { staticClass: "flex flex-aroundbox" }, [
              _c(
                "div",
                {
                  staticClass: "nogocer",
                  on: {
                    click: function($event) {
                      _vm.nocer = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c("div", { staticClass: "nogocercolor" }, [
                _c("div", { on: { click: _vm.goFace } }, [_vm._v("去认证")])
              ])
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }